export enum UserPermission {
    NONE = 1 << 0,
    READ_RAPORT_YEAR = 1 << 1,
    WRITE_RAPORT_YEAR = 1 << 2,
    READ_RAPORT_MONTH = 1 << 3,
    WRITE_RAPORT_MONTH = 1 << 4,
    READ_RAPORT_MODERATOR = 1 << 5,
    WRITE_RAPORT_MODERATOR = 1 << 6,
    READ_USER_TRANSACTIONS = 1 << 7,
    READ_USER_WARNS = 1 << 8,
    READ_USER_MUTES = 1 << 9,
    READ_USER_KICKS = 1 << 10,
    READ_USER_BANS = 1 << 11,
    WRITE_USER_RESET_COOLDOWNS = 1 << 12,
    WRITE_USER_MIGRATE = 1 << 13,
    WRITE_USER_PURGE = 1 << 14,
    WRITE_USER_PERMISSIONS = 1 << 15,
    WRITE_USER_BADGES = 1 << 16,
    WRITE_USER_NOT_MODIFIABLE = 1 << 17,
    WRITE_USER_GCOIN = 1 << 18,
    WRITE_USER_RESET_LEVEL = 1 << 19,
    READ_REPORTED_BUG = 1 << 20,
    WRITE_REPORTED_BUG = 1 << 21,
    SILENCE_LOGIN = 1 << 22,

    //Future perms
    PLANNED_MESSAGE = 1 << 30,
}

export const parsePermissionByName = (name: string) => {
    switch (name) {
        case 'NONE':
            return UserPermission.NONE;
        case 'READ_RAPORT_YEAR':
            return UserPermission.READ_RAPORT_YEAR;
        case 'WRITE_RAPORT_YEAR':
            return UserPermission.WRITE_RAPORT_YEAR;
        case 'READ_RAPORT_MONTH':
            return UserPermission.READ_RAPORT_MONTH;
        case 'WRITE_RAPORT_MONTH':
            return UserPermission.WRITE_RAPORT_MONTH;
        case 'READ_RAPORT_MODERATOR':
            return UserPermission.READ_RAPORT_MODERATOR;
        case 'WRITE_RAPORT_MODERATOR':
            return UserPermission.WRITE_RAPORT_MODERATOR;
        case 'READ_USER_TRANSACTIONS':
            return UserPermission.READ_USER_TRANSACTIONS;
        case 'READ_USER_WARNS':
            return UserPermission.READ_USER_WARNS;
        case 'READ_USER_MUTES':
            return UserPermission.READ_USER_MUTES;
        case 'READ_USER_KICKS':
            return UserPermission.READ_USER_KICKS;
        case 'READ_USER_BANS':
            return UserPermission.READ_USER_BANS;
        case 'WRITE_USER_RESET_COOLDOWNS':
            return UserPermission.WRITE_USER_RESET_COOLDOWNS;
        case 'WRITE_USER_MIGRATE':
            return UserPermission.WRITE_USER_MIGRATE;
        case 'WRITE_USER_PURGE':
            return UserPermission.WRITE_USER_PURGE;
        case 'WRITE_USER_PERMISSIONS':
            return UserPermission.WRITE_USER_PERMISSIONS;
        case 'WRITE_USER_BADGES':
            return UserPermission.WRITE_USER_BADGES;
        case 'WRITE_USER_NOT_MODIFIABLE':
            return UserPermission.WRITE_USER_NOT_MODIFIABLE;
        case 'WRITE_USER_GCOIN':
            return UserPermission.WRITE_USER_GCOIN;
        case 'WRITE_USER_RESET_LEVEL':
            return UserPermission.WRITE_USER_RESET_LEVEL;
        case 'READ_REPORTED_BUG':
            return UserPermission.READ_REPORTED_BUG;
        case 'WRITE_REPORTED_BUG':
            return UserPermission.WRITE_REPORTED_BUG;
        case 'SILENCE_LOGIN':
            return UserPermission.SILENCE_LOGIN;
        case 'PLANNED_MESSAGE':
            return UserPermission.PLANNED_MESSAGE;
        default:
            return UserPermission.NONE;
    }
}
