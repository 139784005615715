

export const Footer = () => {
    return (
        <>
            <div className={"hidden flex flex-col items-center justify-center bg-gray-200 p-5 text-center"}>

                <div>2024 © <span className={"font-bold text-blue-500"}>MysterDead</span>. Wszelkie prawa zastrzeżone</div>
                <div>Strona opracowana przy współpracy z Menadżera Discorda do prezentacji danych zawartych w aplikacji funkcjonującej na platformię Discord</div>
            </div>
        </>
    );
};
