import {redirect, useLoaderData, useNavigate, useNavigation, useParams} from "react-router";
import React, {useEffect, useState} from "react";
import { useStore } from "../store/store";
import { useTranslation } from "react-i18next";
import {Else, If, Then, When} from "react-if";
import Loader from "../components/Loader";
import {observer} from "mobx-react";
import {SuccessButton} from "../components/ui/SuccessButton";
import {DebugType, parseSnackbarTypeColor} from "../components/model/Snackbar";
import {NoAccess} from "../components/NoAccess";
import {parsePermissionByName, UserPermission} from "../components/model/Permission";
import {DangerButton} from "../components/ui/DangerButton";
import {MigrateAccount} from "../components/modal/MigrateAccount";
import {Input} from "@material-tailwind/react";
export const ManageUser: React.FC = observer(() => {
    const store = useStore();
    const navigate = useNavigate();
    const [load, setLoad] = useState(false);
    const [manageUser, setManageUser]: any | undefined = useState(undefined);
    const [openMigrate, setOpenMigrate] = useState(false);
    const [loadingManageUser, setLoadManageUser] = useState(false);
    const [perm, setPerm] = useState(0);
    const [id, setId] = useState(null);
    const { t, i18n } = useTranslation();
    const loadManageUser = () => {
        if(id == null){
            store.addSnackbar({
                message: "Bez podanego ID nie możesz zarządzać użytkownikiem",
                type: DebugType.ERROR,
                duration: 10000
            })
            return;
        }
        setLoadManageUser(true);
        store.loadProfile(id, false).then(e=> {
            setManageUser(e);
            // setPerm(e.permission);
        }).finally(()=> setLoadManageUser(false));
    }
    const onCheck = (target : any, perms : UserPermission) => {
        if(target.target.checked) {
            setPerm(perm + perms);
        }else{
            setPerm(perm - perms);
        }
    }
    return (
        <>
            <div>
                <section className="xl:overflow-hidden select-none flex flex-col bg-black min-h-screen text-white">
                    <div className={"flex flex-col items-center text-xl h-max text-white font-montserrat top-40"}>
                        {load ? (<Loader/>) : (store.user.permission & 360448) === 360448 ? (
                            <div className={"h-full w-screen relative"}>
                                <div className={"mt-28 mx-5 xl:mx-48"}>
                                    <div className={"text-2xl xl:text-4xl font-bold mt-10"}>Zarządzanie użytkownikiem
                                    </div>
                                    <div className={"w-full h-1 bg-gray-700 mb-5 mt-5"}></div>
                                    <div className={"flex flex-col xl:flex-row items-center justify-center xl:space-x-10 mt-10"}>
                                        <div className={"w-full"}>
                                            <Input type={'number'} labelProps={{className: 'text-white peer-placeholder-shown:text-white'}} label={"Identyfikator użytkownika"} color={'blue'} crossOrigin={''} disabled={store.user && (store.user.permission & 32) != 32}
                                                   onBlur={(e: any) => {
                                                       setId(e.target.value);
                                                   }} onPaste={(e: any) => {
                                                setId(e.target.value);
                                            }} className="text-white "/>
                                        </div>
                                        <div className={"flex flex-row text-right items-end justify-end"}>
                                            <SuccessButton label={"Edytuj"} press={() => {
                                                loadManageUser();
                                            }} loading={loadingManageUser}/>
                                        </div>
                                    </div>
                                    <When condition={manageUser != undefined}>
                                        <div className={"mt-10 text-xl"}>Zarządzasz
                                            użytkownikiem <b>{manageUser != undefined ? manageUser.username : ''}</b>
                                        </div>
                                        <div className={"w-full h-[1px] bg-gray-700 mb-5 mt-5"}></div>
                                        <div className={"grid grid-cols-1 xl:grid-cols-2 w-full xl:space-x-10"}>
                                            <div>
                                                <div className={"w-full"}>
                                                    <label htmlFor="manage_gcoin"
                                                           className="block mb-2 text-sm text-white">Stan
                                                        gCoinów</label>
                                                    <input id="manage_gcoin" type={'number'}
                                                           value={manageUser != undefined ? manageUser.gCoins : 0}
                                                           className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"/>
                                                    <input id="manage_gcoin" type={'number'}
                                                           value={perm}
                                                           onChange={(e)=> setPerm(Number(e.currentTarget.value))}
                                                           className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"/>
                                                    <div className={"text-xl font-bold mt-5 mb-5"}>Akcje</div>
                                                    <div className={"space-y-3"}>
                                                        <SuccessButton label={'Otwórz profil'} press={() => {
                                                        }} loading={false}/>
                                                        <When
                                                            condition={(store.user.permission & UserPermission.WRITE_USER_BADGES) === UserPermission.WRITE_USER_BADGES}><SuccessButton
                                                            label={'Zarządzaj odznakami'} press={() => {
                                                        }} loading={false}/></When>
                                                        <When
                                                            condition={(store.user.permission & UserPermission.WRITE_USER_RESET_COOLDOWNS) === UserPermission.WRITE_USER_RESET_COOLDOWNS}><DangerButton
                                                            label={'Zresetuj limity ekonomii'} press={() => {
                                                        }} loading={false}/></When>
                                                        <When
                                                            condition={(store.user.permission & UserPermission.WRITE_USER_MIGRATE) === UserPermission.WRITE_USER_MIGRATE}><DangerButton
                                                            label={'Migracja danych na inne konto'} press={() => {
                                                            setOpenMigrate(true);
                                                        }} loading={false}/></When>
                                                        <MigrateAccount open={openMigrate} setOpen={setOpenMigrate}/>
                                                        <When
                                                            condition={(store.user.permission & UserPermission.WRITE_USER_PURGE) === UserPermission.WRITE_USER_PURGE}><DangerButton
                                                            label={'Skasuj wszystkie dane (Po wykonaniu akcji dane przepadają)'}
                                                            press={() => {
                                                            }} loading={false}/></When>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={"w-full"}>
                                                <div className={"font-bold text-lg"}>Uprawnienia</div>
                                                <div className={"mb-5 text-sm text-gray-500"}>Aktualny poziom uprawnień wynosi <span className={"text-white font-bold"}>{perm}</span></div>
                                                <pre>
                                                    {Object.keys(UserPermission).filter(e => isNaN(Number(e))).map((e: any) => (<>

                                                        <div className="flex items-center mb-4">
                                                            <input id={`checkbox-${e}`} type="checkbox"
                                                                   disabled={(store.user.permission & UserPermission.WRITE_USER_PERMISSIONS) != UserPermission.WRITE_USER_PERMISSIONS}
                                                                   onChange={d => onCheck(d, parsePermissionByName(e)!)}
                                                                   checked={((perm & parsePermissionByName(e)!) === parsePermissionByName(e))}
                                                                   className="w-5 h-5 text-blue-600 bg-red-500 border-red-300 rounded focus:ring-blue-600 focus:ring-1"/>
                                                            <label htmlFor={`checkbox-${e}`}
                                                                   className="ms-2 text-sm font-medium text-white">{e}</label>
                                                        </div>
                                                    </>))}
                                                </pre>
                                            </div>
                                        </div>
                                    </When>
                                </div>
                            </div>
                        ) : <NoAccess/>}
                    </div>
                </section>
            </div>
        </>
    );
});
