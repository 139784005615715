export interface Snackbar {
    message: string;
    type: DebugType;
    hide?: boolean;
    timestamp?: number;
    duration: number;
}

export enum DebugType {
    INFO = 'INFO',
    WARN = 'WARN',
    ERROR = 'ERROR',
    DEBUG = 'DEBUG',
}

export const parseSnackbarType = (type: DebugType) => {
    switch (type) {
        case DebugType.INFO:
            return "pSST";
        case DebugType.WARN:
            return "Uwaga"
        case DebugType.ERROR:
            return "Błąd";
        case DebugType.DEBUG:
            return "Debug";
        default:
            return "Nieznany";
    }
}

export const parseSnackbarTypeColor = (type: DebugType) => {
    switch (type) {
        case DebugType.INFO:
            return "bg-green-800";
        case DebugType.WARN:
            return "bg-yellow-800";
        case DebugType.ERROR:
            return "bg-red-800";
        case DebugType.DEBUG:
            return "bg-purple-800";
    }
    return "Unknow"
}

export const parseSnackbarColor = (type: DebugType) => {
    switch (type) {
        case DebugType.INFO:
            return "bg-green-600";
        case DebugType.WARN:
            return "bg-orange-600";
        case DebugType.ERROR:
            return "bg-red-600";
        case DebugType.DEBUG:
            return "bg-purple-600";
    }
    return "text-gray"
}
