import banner from "../assets/banner.png";
import React from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router";

export const NotFound = () => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    return (
        <>
            <div className={"flex flex-col items-center justify-center w-full h-full bg-black"}>
                <div
                    className={"flex flex-col items-center justify-center text-white w-full h-full text-center font-montserrat"}>
                    <div className="flex flex-row items-center space-x-7 mb-14">
                        <img src={banner} className="h-8 xl:h-16" alt="Banner"/>
                        <div className="text-2xl xl:text-4xl font-bold font-montserrat">
                            X
                        </div>
                        <img
                            src="https://cdn.prod.website-files.com/6257adef93867e50d84d30e2/636e0b52aa9e99b832574a53_full_logo_blurple_RGB.png"
                            alt="discord"
                            className="h-5 xl:h-8"
                        />
                    </div>
                    <div className={"text-3xl font-bold mt-32"}>{t('notFound.title')}</div>
                    <div className={"text-lg text-gray-500"}>{t('notFound.subtitle')}
                    </div>
                    <button
                        className="flex items-center mt-24 justify-center text-white px-4 py-2 text-1xl font-bold border border-white rounded-full hover:text-white"
                        onClick={() => {
                            navigate({pathname: '/'})
                        }}
                    >
                        {t('button.return')}
                    </button>
                </div>
            </div>
        </>
    )
}
