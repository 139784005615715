import React from "react";
import {action, computed, flow, makeObservable, observable} from "mobx";
import {DebugType, Snackbar} from "../components/model/Snackbar";

export class Store {
  @observable showAside: boolean;
  @observable apiURL: string;
  @observable token?: any;
  @observable user: any;
  @observable profile?: any;
  @observable lang: string;
  @observable snackbar: Snackbar[];

  constructor() {
    makeObservable(this)
    this.showAside = false;
    this.apiURL = 'https://api.mysterdead.xyz/v1';
    // this.apiURL = 'http://localhost:3001/v1';
    if(localStorage.getItem('user') !=null){
      this.user = JSON.parse(localStorage.getItem("user")!);
    }
    if(localStorage.getItem('token') !=null){
      this.token = localStorage.getItem("token");
    }
    console.log(typeof this.user)
    this.profile=null;
    this.lang = localStorage.getItem("lang") ?? "pl";
    this.snackbar = [];
    if(this.user !=null){
      //Run override lang from user
      this.setLanguage(this.user.preferred_locale);
      this.loadLocalUser();
    }
  }

  @flow
  *addSnackbar(snackbar: Snackbar) {
    switch (snackbar.type){
      case DebugType.INFO:
        console.log(`\u001B[35m[\u001B[32mINFO\u001B[35m] \u001B[0m`, snackbar.message);
        break;
      case DebugType.WARN:
        console.log(`\u001B[35m[\u001B[33mWARN\u001B[35m] \u001B[0m`, snackbar.message);
        break;
      case DebugType.ERROR:
        console.log(`\u001B[35m[\u001B[31mERROR\u001B[35m] \u001B[0m`, snackbar.message);
        break;
      case DebugType.DEBUG:
        console.log(`\u001B[35m[\u001B[34mDEBUG\u001B[35m] \u001B[0m`, snackbar.message);
        break;
    }
    if(!snackbar.hide){
      snackbar.hide = false;
    }
    snackbar.timestamp = Date.now();
    this.snackbar.push(snackbar);
    setTimeout(()=> {
      this.snackbar.map(e=> {
        if(e.timestamp == snackbar.timestamp){
          e.hide = true;
        }
      })
    }, snackbar.duration);
  }

  @action
  removeSnackbar(snack: Snackbar){
    this.snackbar.splice(this.snackbar.indexOf(snack), 1)
  }


  @action
  async loadLocalUser(){
    const response = await fetch(`${this.apiURL}/user/@me`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.token}`
      },
    })
    try{
      const json = await response.json();
      this.setUser(json.data);
    }catch (e){
      this.logout();
      store.addSnackbar({
        message: "Sesja wygasła! Zostałeś wylogowany/a",
        type: DebugType.ERROR,
        duration: 50000
      })
    }
  }

  @action
  setLanguage(lang: string) {
    this.lang = lang;
    localStorage.setItem("lang", lang);
  }

  @action
  async searchProfiles(search: string) {
    const response = await fetch(`${store.apiURL}/user/@me/search?content=${search}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.token}`
      },
    })
    try{
      const json = await response.json();
      return json.data
    }catch (e) {
      return null;
    }
  }

  @action
  async getPlannedMessages() {
    const response = await fetch(`${store.apiURL}/admin/planned-message`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.token}`
      },
    })
    try{
      const json = await response.json();
      return json.data
    }catch (e) {
      return null;
    }
  }

  @action
  async getChannels() {
    const response = await fetch(`${store.apiURL}/admin/channels`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.token}`
      },
    })
    try{
      const json = await response.json();
      return json.data
    }catch (e) {
      return null;
    }
  }

  @action
  async cancelPlannedMessage(id: string): Promise<any> {
    const response = await fetch(`${store.apiURL}/admin/planned-message/${id}`, {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${this.token}`
      },
    })
    const json = await response.json();
    if(json.statusCode !=200){
      this.addSnackbar({
        message: "Ups! Nie udało nam anulować zaplanowanej wiadomości",
        type: DebugType.ERROR,
        duration: 10000
      })
      return null;
    }
    this.addSnackbar({
      message: "Wysyłka wiadomości została anulowana",
      type: DebugType.INFO,
      duration: 10000
    })
    return json.data;
  }


  @action
  async sendNow(id: string): Promise<any> {
    const response = await fetch(`${store.apiURL}/admin/planned-message/${id}`, {
      method: 'PATCH',
      headers: {
        'Authorization': `Bearer ${this.token}`
      },
    })
    const json = await response.json();
    if(json.statusCode !=200){
      this.addSnackbar({
        message: "Ups! Nie udało nam wysłać zaplanowanej wiadomości",
        type: DebugType.ERROR,
        duration: 10000
      })
      return null;
    }
    this.addSnackbar({
      message: "Wiadomość została wysłana",
      type: DebugType.INFO,
      duration: 10000
    })
    return json.data;
  }

  @action
  async planMessage(data: {content?: string, channel: string, deliveryDate: Date}): Promise<any> {
    const response = await fetch(`${store.apiURL}/admin/planned-message`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.token}`
      },
      body: JSON.stringify(data)
    })
    const json = await response.json();
    if(json.statusCode !=200){
      this.addSnackbar({
        message: "Ups! Nie udało nam zaplanować twojej wiadomości",
        type: DebugType.ERROR,
        duration: 10000
      })
      return null;
    }
    this.addSnackbar({
      message: "Wiadomość została zaplanowana",
      type: DebugType.INFO,
      duration: 10000
    })
    return json.data;
  }

  @action
  async loadProfile(ide: string, fullProfile: boolean = true) {
    let id = ide;
    if(this.user !=null && ide === this.user.id){
      id = '@me'
    }
    const response = await fetch(`${store.apiURL}/user/${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.token}`
      },
    })
    try{
      const json = await response.json();
      let data = json.data;
      if(!fullProfile) return data;
      data['leaderboardEconomy'] = await this.loadLeaderboardRankLevel(data.id, 'GCOINS');
      data['leaderboardLevel'] = await this.loadLeaderboardRankLevel(data.id, 'LEVEL');
      data['transactions'] = [];
      data['warns'] = [];
      data['mutes'] = [];
      data['bans'] = [];
      data['kicks'] = [];
      if(this.user !=null){
        if((this.user.permission & 128) === 128 || this.user.id === data.id) {
          data['transactions'] = await this.loadTransactions(id);
        }
        if((this.user.permission & 256) === 256 || this.user.id === data.id) {
          data['warns'] = await this.loadWarns(id);
        }
        if((this.user.permission & 512) === 512 || this.user.id === data.id) {
          data['mutes'] = await this.loadMutes(id);
        }
        if((this.user.permission & 1024) === 1024 || this.user.id === data.id) {
          data['bans'] = await this.loadBans(id);
        }
        if((this.user.permission & 2048) === 2048 || this.user.id === data.id) {
          data['kicks'] = await this.loadKick(id);
        }
      }
      this.profile = data;
      return this.profile;
    }catch (e) {
      return null;
    }
  }

  @action
  async loadLeaderboardRankLevel(id: string, property: string): Promise<number> {
    const response = await fetch(`${store.apiURL}/user/${id}/rank?property=${property}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.token}`
      },
    })
    const json = await response.json();
    return json.data;
  }

  @action
  async loadTransactions(id: string): Promise<any> {
    const response = await fetch(`${store.apiURL}/user/${id}/transactions`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.token}`
      },
    })
    const json = await response.json();
    if(json.statusCode !=200){
      return [];
    }
    return json.data;
  }

  @action
  async downloadRaportMonth(year: string, month: string): Promise<any> {
    const response = await fetch(`${store.apiURL}/admin/raport/month?year=${year}&month=${month}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${this.token}`
      },
    })
    response.blob().then(blob => URL.createObjectURL(blob))
        .then(url => {
          const a = document.createElement("a");
          a.href = url;
          a.setAttribute('download', `Raport-${year}-${month}.xlsx`);
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          URL.revokeObjectURL(url);
        });
  }

  @action
  async downloadRaportYear(year: string): Promise<any> {
    const response = await fetch(`${store.apiURL}/admin/raport/year?year=${year}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${this.token}`
      },
    })
    response.blob().then(blob => URL.createObjectURL(blob))
        .then(url => {
          const a = document.createElement("a");
          a.href = url;
          a.setAttribute('download', `Raport-${year}.xlsx`);
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          URL.revokeObjectURL(url);
        });
  }

  @action
  async downloadModeratorRaport(id: string, year: string, month?: any): Promise<any> {
    const response = await fetch(`${store.apiURL}/admin/raport/user/${id}?year=${year}${month !=null ? `&month=${month}` : ""}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${this.token}`
      },
    })
    response.blob().then(blob => URL.createObjectURL(blob))
        .then(url => {
          const a = document.createElement("a");
          a.href = url;
          a.setAttribute('download', month !=null ? `Raport-${id}-${month}-${year}.xlsx` : `Raport-${id}-${year}.xlsx`);
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          URL.revokeObjectURL(url);
        });
  }

  async loadRaportDetails(param: string, moderator?: string | undefined): Promise<any> {
    const response = await fetch(`${store.apiURL}/admin/raport?props=${param}${moderator !=null ? `&moderator=${moderator}` : ''}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.token}`
      },
    });
    try {
      const json = await response.json();
      if(json.statusCode !=200){
        return null;
      }
      console.log(json)
      return json.data;
    }catch (e){
      return null;
    }
  }

  @action
  async loadWarns(id: string): Promise<any> {
    const response = await fetch(`${store.apiURL}/user/${id}/history?type=WARN`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.token}`
      },
    })
    const json = await response.json();
    if(json.statusCode !=200){
      return [];
    }
    return json.data;
  }

  @action
  async saveLeavelCard(card: {background_image?: string, background_color: string, primary_text_color: string, secondary_text_color: string, progress_fill_color: string, progress_tint_color: string}): Promise<any> {
    const response = await fetch(`${store.apiURL}/user/@me/card`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.token}`
      },
      body: JSON.stringify(card)
    })
    const json = await response.json();
    if(json.statusCode !=200){
      this.addSnackbar({
        message: "Ups! Nie udało nam się zapisać twojej karty poziomu",
        type: DebugType.ERROR,
        duration: 10000
      })
      return null;
    }
    this.addSnackbar({
      message: "Karta poziomu została zaktualizowana",
      type: DebugType.INFO,
      duration: 10000
    })
    return json.data;
  }

  @action
  async loadMutes(id: string): Promise<any> {
    const response = await fetch(`${store.apiURL}/user/${id}/history?type=TIMEOUT`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.token}`
      },
    })
    const json = await response.json();
    if(json.statusCode !=200){
      return [];
    }
    return json.data;
  }

  @action
  async loadBans(id: string): Promise<any> {
    const response = await fetch(`${store.apiURL}/user/${id}/history?type=BAN`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.token}`
      },
    })
    const json = await response.json();
    if(json.statusCode !=200){
      return [];
    }
    return json.data;
  }

  get calculateProgress() {
    const cx = this.user.xp;
    const rx = this.user.xpRequired;
    return (cx * 100) / rx;
  }

  @action
  async loadKick(id: string): Promise<any> {
    const response = await fetch(`${store.apiURL}/user/${id}/history?type=KICK`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.token}`
      },
    })
    const json = await response.json();
    if(json.statusCode !=200){
      return [];
    }
    return json.data;
  }

  existUser = () : boolean => {
    return this.user != null;
  }

  setToken = (token: any) => {
    this.token = token;
    if(token == null){
      localStorage.removeItem("token");
    }else{
      localStorage.setItem("token", token);
    }
  }

  @action
  setUser = (user: any) => {
    this.user = user;
    if(user == null){
      localStorage.removeItem("user");
    }else{
      localStorage.setItem("user", JSON.stringify(user));
    }
  }

  /**
   * ! showAside - Show sidebar
   */
  @action
  showAsideToggle() {
    this.showAside = !this.showAside;
    console.log(this.showAside);
  }

  @action
  logout(){
    localStorage.removeItem('user')
    localStorage.removeItem('token')
    this.user = null;
    this.token = null;
  }
}
const StoreContext = React.createContext<Store>({} as Store);
export const store = new Store();

export const StoreProvider = ({
  children,
}: {
  children: React.ReactElement;
}) => {
  return (
    <StoreContext.Provider value={store}> {children} </StoreContext.Provider>
  );
};

export const useStore = () => React.useContext(StoreContext);
