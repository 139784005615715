import { Button, Spinner } from "@material-tailwind/react";
import { observer } from "mobx-react";

export const SuccessButton = observer((props: { label: string; press: Function, loading: boolean }) => {
    return (
        <Button className="bg-green-500 flex items-center font-montserrat" onClick={() => props.press()}>
            <Spinner className={`mr-4 ${!props.loading ? 'hidden' : ''}`}></Spinner> {props.label}
        </Button>
    )
});
