import { redirect, useNavigate } from "react-router";
import React, {useEffect, useState} from "react";
import { useStore } from "../store/store";
import { useTranslation } from "react-i18next";
import banner from "../assets/banner.png";
import gcoin from "../assets/gcoin.webp";
import { Else, If, Then } from "react-if";
import {useSearchParams} from "react-router-dom";
export const LoginPage = () => {
    const navigate = useNavigate();
    const store = useStore();
    const { t, i18n } = useTranslation();
    const [searchParams, setSearchParams] = useSearchParams();
    const [show, setShow] = useState(false);
    const token = searchParams.get("token");
    const status = searchParams.get("success") === 'true';
    // @ts-ignore
    useEffect(() => {
        const response = fetch(`${store.apiURL}/user/@me`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        }).then((e)=> {
            e.json().then((m)=> {
                const data = m.data;
                store.setToken(token);
                store.setUser(data);
            })
        });
    });
    return (
        <>
            <div>
                <section className="overflow-y-auto select-none flex flex-col bg-black text-white">
                    <div className={"flex flex-col items-center justify-center h-screen text-xl text-white font-sans"}>
                        <If condition={status}>
                            <Then>
                                <div className={"text-green-500 text-2xl font-bold"}>Sukces</div>
                                <div className={"text-lg font-bold"}>Za kilka sekund zostaniesz przeniesiony na stronę
                                    panelu
                                </div>
                                <button
                                    className="flex items-center mt-10 justify-center text-white px-4 py-2 text-1xl font-bold border border-white rounded-full hover:text-white"
                                    onClick={() => {
                                        navigate({pathname: '/profile/'+store.user.id})
                                    }}
                                >
                                    Przejdź do profilu
                                </button>
                            </Then>
                            <Else>
                                <Then>
                                    <div className={"text-red-500 text-2xl font-bold"}>Błąd logowania</div>
                                    <div className={"text-lg font-bold"}>Zbieramy informację o twoim problemie</div>
                                    <div className={"text-gray-500 text-sm font-bold"}>Spróbuj ponownie później</div>
                                    <button
                                        className="flex items-center mt-10 justify-center text-white px-4 py-2 text-1xl font-bold border border-white rounded-full hover:text-white"
                                        onClick={() => {
                                            navigate({pathname: '/'})
                                        }}
                                    >
                                        Wróć na stronę główną
                                    </button>
                                </Then>
                            </Else>
                        </If>
                    </div>
                </section>
            </div>
        </>
    );
};
