import React from "react";
import {DangerButton} from "./ui/DangerButton";
import {useNavigate} from "react-router";
import {useTranslation} from "react-i18next";
import {When} from "react-if";

export const UserItem = (props: {user: any}) => {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    return (<>
        <div className={"bg-[#D9D9D9] block rounded-2xl shadow-lg p-4 w-full font-montserrat h-12/12"}>
            <div className={'rounded-2xl relative w-full h-52'}
                 style={{
                     backgroundImage: `url(https://cdn.discordapp.com/banners/${props.user.id}/${props.user.banner}?size=1024)`,
                     backgroundSize: 'cover',
                     backgroundRepeat: "no-repeat",
                     backgroundPosition: "center",
                     backgroundColor: props.user.bannerColor !=null ? `#${props.user.bannerColor.toString(16)}` : 'red'
                 }}>
                <img
                    className={"-bottom-32 absolute h-24 md:h-auto rounded-2xl left-[50%] translate-y-[-50%] translate-x-[-50%]"}
                    src={`https://cdn.discordapp.com/avatars/${props.user.id}/${props.user.avatar}`}/>
            </div>
            <div className={'mt-24'}></div>
            <div className={'flex flex-col justify-between'}>
                <div>
                    <div
                        className={"text-3xl font-bold"}>{props.user.global_name != null ? props.user.global_name : "\u200B"}</div>
                    <div className={"text-2xl font-bold"}>{props.user.username}</div>
                </div>
                <div className={"flex items-end justify-center mt-10"}>
                    <DangerButton label={t('button.checkProfile')} press={()=> {navigate({pathname: '/profile/' + props.user.id})}} loading={false}/>
                </div>
            </div>
        </div>
    </>);
}
