import React from "react";
import {observer} from "mobx-react";
import {Outlet} from "react-router";
import {LandingNavbar} from "./navigation/LandingNavbar";
import {useStore} from "../store/store";
import {parseSnackbarColor, parseSnackbarType, parseSnackbarTypeColor} from "./model/Snackbar";
import {Footer} from "./Footer";

export const Layout: React.FC = observer(() => {
    const store = useStore();
    return (
        <>
            <div className={"w-screen h-screen overflow-hidden"}  contextMenu={"return false;"}>
                <div className={"h-screen w-screen"}>
                    <div className={"h-1/12"}>
                        <LandingNavbar/>
                    </div>
                    <div className={"h-11/12 w-full overflow-y-scroll overflow-x-hidden scrollbar"}>
                        <Outlet/>
                    </div>
                    <div className={"fixed bottom-0"}>
                        {store.snackbar.filter(e => !e.hide).map(e => (
                            <div className={"text-center py-2 xl:px-4 items-center justify-center"} key={e.timestamp}>
                                <div
                                    className={`p-2 ${parseSnackbarColor(e.type)} items-center text-white leading-none xl:rounded-full flex xl:inline-flex`}>
                            <span
                                className={`flex rounded-full ${parseSnackbarTypeColor(e.type)} uppercase px-2 py-1 text-xs font-bold mr-3`}>{parseSnackbarType(e.type)}</span>
                                    <span
                                        className={"font-semibold mr-2 text-left flex-auto"}>{e.message}</span>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <Footer/>
            </div>
        </>
    );
});
