import {redirect, useLoaderData, useNavigate, useNavigation, useParams} from "react-router";
import React, {useEffect, useState} from "react";
import { useStore } from "../store/store";
import { useTranslation } from "react-i18next";
import { pl } from "react-day-picker/locale";
import Loader from "../components/Loader";
import {observer} from "mobx-react";
import {SuccessButton} from "../components/ui/SuccessButton";
import {NoAccess} from "../components/NoAccess";
import {Input, Option, Popover, PopoverContent, PopoverHandler, Select, Textarea} from "@material-tailwind/react";
import { JSX } from "react/jsx-runtime";
import {DayPicker} from "react-day-picker";
import {format} from "date-fns";
import {PlannedMessageComponent} from "../components/PlannedMessage";
import {Editable, Slate, withReact} from "slate-react";
import {createEditor} from "slate";
export const PlannedMessage: React.FC = observer(() => {
    const store = useStore();
    const navigate = useNavigate();
    const [load, setLoad] = useState(true);
    const [embed, setEmbed] = useState(false);
    const [date, setDate] = React.useState<Date>();
    const [time, setTime] = useState('00:00');
    const [embed2, setEmbed2] :any = useState(null);
    const [plannedMessages, setPlannedMessages] :any = useState([]);
    const [month, setMonth] = React.useState<Date>();
    const [channels, setChannels] = useState([])
    const [channel, setChannel] = useState(null);
    const {t, i18n} = useTranslation();
    
    //test
    const editor = withReact(createEditor())
    const value = [{ type: 'block', children: [{ text: 'test' }] }]
    useEffect(() => {
        setMonth(new Date());
        setDate(new Date())
        setEmbed2({
            "content" : "",
            "embed": undefined
        })
        store.getChannels().then(e => {
            e.sort((e: any, d: any) => e.position - d.position);
            setChannels(e);
        }).finally(() => {
            setLoad(false);
        });
        store.getPlannedMessages().then(e=> {
            setPlannedMessages(e);
        })
    }, []);
    const sendMessage = (message: any) => {
        store.sendNow(message.id).then(e=> {
            store.getPlannedMessages().then(e=> {
                setPlannedMessages(e);
            })
        })
    }
    const cancelMessage = (message: any) => {
        store.cancelPlannedMessage(message.id).then(e=> {
            store.getPlannedMessages().then(e=> {
                setPlannedMessages(e);
            })
        })
    }
    const save = () => {
        let local = new Date();
        local.setHours(Number(time.split(":")[0]), Number(time.split(":")[1]));
        local.setMonth(month?.getMonth()!)
        local.setDate(date!.getDate())
        setDate(local);
        console.log(date)
        store.planMessage({
            content: embed2.content,
            deliveryDate: local,
            channel: channel!
        })
    }
    const updateMessage = (value: string) => {
        let card = Object.assign({}, embed2);
        card.content = value;
        setEmbed2(card)
    }
    const updateMessageEmbed = (prop: string, value: string) => {
        let card = Object.assign({}, embed2);
        card.embed[prop] = value;
        setEmbed2(card)
    }
    const buildOptions = () => {
        let option: JSX.Element[] = [];
        const d = channels.filter((e: any) => e.type == 4);
        d.forEach((e: any)=> {
            option.push(<Option value={e.id} disabled={true}>{e.name}</Option>)
            const m = channels.filter((x:any) => x.parent_id === e.id);
            m.sort((e :any,d :any) => e.position - d.position );
            m.map((e:any) => option.push(<Option value={e.id}>{e.name}</Option>))
        })
        return option;
    }
    return (
        <>
            <div>
                <section className="xl:overflow-hidden select-none flex flex-col bg-black min-h-screen text-white">
                    <div className={"flex flex-col items-center text-xl h-max text-white font-montserrat top-40"}>
                        {load ? (<Loader/>) : (store.user.permission & 8388608) === 8388608 ? (
                            <div className={"h-full w-screen relative"}>
                                <div className={"mt-28 mx-5 xl:mx-48"}>
                                    <div className={"text-2xl xl:text-4xl font-bold mt-10"}>Planowanie wysyłki
                                        wiadomości
                                    </div>
                                    <div className={'space-y-10 mt-10'}>
                                        <div>
                                            <Select className={'text-white'} size={'lg'}
                                                    labelProps={{className: 'text-white peer-placeholder-shown:text-white'}}
                                                    color={'blue'}
                                                    label={'Wybierz kanał do wysyłki'}
                                                    onChange={(e: any) => setChannel(e)}>
                                                {buildOptions()}
                                            </Select>
                                        </div>
                                        {time}
                                        {/*{date!.toLocaleString("pl", {timeZone: "Europe/Warsaw"})}*/}
                                        <div>
                                            <Textarea defaultValue={embed2.content}
                                                      labelProps={{className: 'text-white peer-placeholder-shown:text-white'}}
                                                      onInput={(e: any) => updateMessage(e.target.value)}
                                                      className={'text-white'}
                                                      color={'blue'} label="Treść wiadomości"/>
                                        </div>
                                        <Popover placement="bottom">
                                            <PopoverHandler>
                                                <Input
                                                    className={'text-white'}
                                                    labelProps={{className: 'text-white peer-placeholder-shown:text-white'}}
                                                    label="Wybierz date"
                                                    crossOrigin={''}
                                                    value={date ? format(date, "PPP", {locale: pl}) : ""}
                                                />
                                            </PopoverHandler>
                                            <PopoverContent className={'bg-[#111111]'}>
                                                <DayPicker
                                                    mode="single"
                                                    locale={pl}
                                                    selected={date}
                                                    timeZone="Europe/Warsaw"
                                                    onSelect={setDate}
                                                    showOutsideDays={false}
                                                    showWeekNumber
                                                    onMonthChange={(e)=> setMonth(e)}
                                                    disabled={{ before: new Date() }}
                                                    className="border-0"
                                                    classNames={{
                                                        caption: "flex justify-center py-2 mb-4 relative items-center",
                                                        caption_label: "text-white font-bold text-2xl",
                                                        nav: "flex items-center justify-center",
                                                        nav_button:
                                                            "h-6 w-6 bg-transparent hover:bg-blue-gray-50 p-1 rounded-md transition-colors duration-300",
                                                        nav_button_previous: "absolute left-1.5",
                                                        nav_button_next: "absolute right-1.5",
                                                        day: "h-9 w-9 text-center p-0 font-normal",
                                                        today: `rounded-md bg-gray-200 text-gray-900`, // Add a border to today's date
                                                        selected: `rounded-md bg-gray-800 text-white hover:bg-gray-900 hover:text-white focus:bg-gray-900 focus:text-white`, // Highlight the selected day
                                                        root: `shadow-lg p-5`, // Add a shadow to the root element
                                                        chevron: ` fill-amber-500 space-y-5 select-none` // Change the color of the chevron
                                                    }}
                                                />
                                            </PopoverContent>
                                        </Popover>
                                        <Input
                                            className={'text-white'}
                                            labelProps={{className: 'text-white peer-placeholder-shown:text-white'}}
                                            label="Wybierz czas"
                                            type={'time'}
                                            min={'00:00'}
                                            max={'23:59'}
                                            onInput={(e: any)=> setTime(e.target.value)}
                                            crossOrigin={''}
                                            defaultValue={time}
                                        />
                                        <Slate editor={editor} value={value}>
                                            <Editable

                                            />
                                        </Slate>
                                    </div>

                                    <div className={'flex items-center justify-between mt-10'}>
                                        <div className={'font-bold text-xl'}>Treść embed</div>
                                        <SuccessButton label={embed ? 'Usuń' : 'Dodaj'} press={() => {
                                            setEmbed(!embed)
                                        }} loading={false}/>
                                    </div>
                                    <div className={'flex items-end justify-end mt-10'}>
                                        <SuccessButton label={'Zaplanuj'} press={() => {
                                            save()
                                        }} loading={false}/>
                                    </div>
                                </div>
                                <div className={'mt-10 mx-5 xl:mx-48'}>
                                    <div className={"text-2xl xl:text-4xl font-bold mt-10"}>Lista planowanych wiadomości</div>
                                    {plannedMessages.map((e: any) => (<PlannedMessageComponent message={e} sendNow={()=> sendMessage(e)} cancelSend={()=>cancelMessage(e)}/>))}
                                </div>
                            </div>
                        ) : <NoAccess/>}
                    </div>
                </section>
            </div>
        </>
    );
});
