import React from "react";
import {DangerButton} from "./ui/DangerButton";
import {useNavigate} from "react-router";
import {useTranslation} from "react-i18next";
import {SuccessButton} from "./ui/SuccessButton";

export const PlannedMessageComponent = (props: {message: any, sendNow: Function, cancelSend: Function}) => {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    return (<>
        <div className={"bg-[#111111] block rounded-2xl shadow-lg p-4 w-full"}>
            <div className={"flex items-center"}>
                <div className={'text-xl font-bold'}>Identyfikator wysyłki:</div>
                <div className={'text-md text-gray-500 ml-1'}>{props.message.id}</div>
            </div>
            <div className={"flex items-center"}>
                <div className={'text-xl font-bold'}>Planowany czas wysyłki:</div>
                <div
                    className={'text-md text-gray-500 ml-1'}>{new Date(props.message.deliveryDate).toLocaleString()}</div>
            </div>
            <div className={"flex items-center"}>
                <div className={'text-xl font-bold'}>Utworzono:</div>
                <div
                    className={'text-md text-gray-500 ml-1'}>{new Date(props.message.plannedDate).toLocaleString()}</div>
            </div>
            <div className={"flex items-end justify-end mt-5"}>
                <SuccessButton label={'Wyślij teraz'} press={() => {
                    props.sendNow();
                }} loading={false}/>
                <DangerButton label={'Anuluj wysyłkę'} press={() => {
                    props.cancelSend();
                }} loading={false}/>
            </div>
        </div>
    </>);
}
